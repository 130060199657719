import { render, staticRenderFns } from "./TeTopWork.vue?vue&type=template&id=86dabcd8&scoped=true&lang=pug"
var script = {}
import style0 from "./TeTopWork.vue?vue&type=style&index=0&id=86dabcd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86dabcd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeCommonTitleSectionBold: require('/vercel/path0/components/Te/TeCommon/Title/TeCommonTitleSectionBold/TeCommonTitleSectionBold.vue').default})
