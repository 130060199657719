var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"comparison-school co-bg-striped co-px-8 co-px-md-16 co-py-80",attrs:{"id":"comparison-school-test"}},[_c('div',{staticClass:"co-container-lg"},[_c('TeCommonTitleSectionBold',[_vm._v("他スクールとの比較")]),_vm._m(0),_c('table',{staticClass:"te-comparison-school-switch-table table text-center"},[_vm._m(1),_c('tbody',[_vm._m(2),_vm._m(3),(_vm.bannerguarantee)?_c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),_vm._m(8),_vm._m(9),(!_vm.virtualCampus || !_vm.learningContent)?_c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]):_vm._e(),(_vm.virtualCampus)?_c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)]):_vm._e(),(_vm.learningContent)?_c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21)]):_vm._e(),_vm._m(22),_vm._m(23),_vm._m(24),(_vm.benefits)?_c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_vm._m(25),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block font-weight-bold"},[_vm._m(26),_c('br'),(_vm.virtualCampus || _vm.learningContent)?_c('span',[_vm._v("最大15万円以上還元")]):_c('span',{staticClass:"co-text-gray-2 te-table-cell-text"},[_vm._v("最大56万円"),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("還元")])])])]),_vm._m(27),_vm._m(28)]):_vm._e()])]),_c('p',{staticClass:"co-display-13 co-text-gray-3 co-mt-4 co-mb-0"},[(_vm.virtualCampus || _vm.learningContent)?_c('span',[_vm._v("※19時から23時")]):_c('span',[_vm._v("※案件保証は実力判定テスト合格した場合のみ")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"co-text-gray-2 co-mb-48 text-center"},[_c('span',{staticClass:"d-inline d-md-block"},[_vm._v("他のスクールと比較するとテックアカデミーの")]),_c('span',{staticClass:"d-inline d-md-block"},[_vm._v("圧倒的なコストパフォーマンスをご確認いただけます。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',{staticClass:"te-h-lg-100px te-head-tr"},[_c('th',{staticClass:"te-blank-cell"}),_c('th',{staticClass:"co-bg-primary-1 te-teckacademy-cell align-middle"},[_c('img',{staticClass:"w-75 img-fluid d-none d-md-block mx-auto",attrs:{"src":require("assets/images/Te/TeCommon/Logo/logo.svg"),"alt":"テックアカデミー","width":"300","height":"39","loading":"lazy"}}),_c('span',{staticClass:"co-text-white-1 font-weight-bold d-block d-md-none"},[_vm._v("テックアカデミー")])]),_c('th',{staticClass:"co-bg-gray-4 co-text-white-1 te-normal-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_vm._v("A社")])]),_c('th',{staticClass:"co-bg-gray-4 co-text-white-1 te-normal-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_vm._v("B社")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle border-top"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("副業案件")]),_c('span',[_vm._v("保証")])])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-double-circle te-icon-dobble-circle te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◎"}})]),_c('br'),_c('span',{staticClass:"co-marker-yellow-1 font-weight-bold co-text-gray-2 te-table-cell-text"},[_vm._v("1ヶ月以内に紹介")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon co-mb-4 co-mb-md-8",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("まずは転職")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon co-mb-4 co-mb-md-8",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("提携先を紹介")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("案件の進行")]),_c('span',[_vm._v("サポート")])])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-double-circle te-icon-dobble-circle te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◎"}})]),_c('br'),_c('span',{staticClass:"font-weight-bold d-block d-md-inline-block co-text-gray-2 te-table-cell-text"},[_vm._v("サポートあり")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon co-mb-4 co-mb-md-8",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("なし")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon co-mb-4 co-mb-md-8",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("なし")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_vm._v("実案件の提供")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-8"},[_c('i',{staticClass:"icon-original icon-original-double-circle te-icon-dobble-circle te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◎"}})]),_c('br'),_c('span',{staticClass:"font-weight-bold d-block d-md-inline-block"},[_vm._v("3案件を確約")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("転職")]),_c('span',[_vm._v("サポート")])])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-primary-1 co-line-height-1 te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"d-block co-text-gray-2 font-weight-bold te-table-cell-text"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("副業から")]),_c('span',[_vm._v("転職も")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("可能")])])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"d-block te-table-cell-sub-text"},[_vm._v("条件付き")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"d-block te-table-cell-sub-text"},[_vm._v("提携サービス")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("講師の")]),_c('span',[_vm._v("特徴")])])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-double-circle te-icon-dobble-circle te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◎"}})]),_c('br'),_c('span',{staticClass:"font-weight-bold d-block d-md-inline-block co-text-gray-2 te-table-cell-text"},[_vm._v("現役エンジニア")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-triangle co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"△"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("学生や卒業生")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-gray-4 co-line-height-1 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_c('span',{staticClass:"d-block d-md-inline co-mr-md-4"},[_vm._v("卒業生")]),_c('span',[_vm._v("実務経験者")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("マンツーマン")]),_c('span',[_vm._v("メンタリング")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-primary-1 co-line-height-1 te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"d-block co-text-gray-2 font-weight-bold te-table-cell-text"},[_vm._v("現役エンジニア")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("なし")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("なし")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_vm._v("バーチャルキャンパス")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4"},[_c('i',{staticClass:"fa fa-circle-o co-display-4 co-text-gray-1 co-line-height-1",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',[_vm._v("24時間ログイン可能、講師常駐"),_c('span',{staticClass:"co-display-14"},[_vm._v("※")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-display-6 co-text-gray-4",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',[_vm._v("なし")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block"},[_c('i',{staticClass:"fa fa-close co-display-6 co-text-gray-4",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',[_vm._v("なし")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_vm._v("学習コンテンツ"),_c('br'),_vm._v("（イベント）")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-primary-1 co-line-height-1 te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',[_vm._v("24時間ログイン可能、講師常駐"),_c('span',{staticClass:"co-display-14"},[_vm._v("※")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',[_vm._v("なし")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',[_vm._v("なし")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("チャット")]),_c('span',[_vm._v("質問")])])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-double-circle te-icon-dobble-circle te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◎"}})]),_c('br'),_c('span',{staticClass:"font-weight-bold d-block d-md-inline-block co-text-gray-2 te-table-cell-text"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("現役エンジニアが")]),_c('span',[_vm._v("回答")])])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-gray-4 co-line-height-1 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("講師が回答")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-gray-4 co-line-height-1 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("講師が回答")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_vm._v("カリキュラム")])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-primary-1 co-line-height-1 te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"d-block font-weight-bold co-text-gray-2 te-table-cell-text"},[_vm._v("期間無期限で閲覧")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-triangle co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"△"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("卒業後1年間は")]),_c('span',[_vm._v("閲覧可")])])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-gray-4 co-line-height-1 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_c('span',{staticClass:"d-block d-md-inline"},[_vm._v("期間無期限で")]),_c('span',[_vm._v("閲覧")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"te-h-lg-100px te-bg-tr"},[_c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_vm._v("受講期間")])]),_c('td',{staticClass:"te-teckacademy-cell align-middle"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-circle-o co-text-primary-1 co-line-height-1 te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◯"}})]),_c('br'),_c('span',{staticClass:"font-weight-bold d-block d-md-inline-block co-text-gray-2 te-table-cell-text"},[_vm._v("柔軟な期間で学べる")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-triangle co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"△"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("最短10週間")])])]),_c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-triangle co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"△"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("90日間")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"te-head-cell align-middle"},[_c('span',{staticClass:"te-switch-block co-text-gray-2"},[_vm._v("給付金")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"icon-original icon-original-double-circle te-icon-dobble-circle te-camparison-table-icon",attrs:{"aria-hidden":"true","aria-label":"◎"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("対象外")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"te-normal-cell align-middle co-display-13"},[_c('span',{staticClass:"te-switch-block"},[_c('span',{staticClass:"d-inline-block co-mb-4 co-mb-md-8"},[_c('i',{staticClass:"fa fa-close co-text-gray-4 te-camparison-table-sub-icon",attrs:{"aria-hidden":"true","aria-label":"✕"}})]),_c('br'),_c('span',{staticClass:"te-table-cell-sub-text"},[_vm._v("対象外")])])])
}]

export { render, staticRenderFns }