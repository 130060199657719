
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { IndexServices } from '~/services/indexService'
import { scriptTagsByConfig } from '~/constants/scriptTags'
import { metaTags } from '~/constants/metaTags'
import TeCommonFixedBottomButton from '~/components/Te/TeCommon/TeCommonFixedBottomButton.vue'

declare global {
  interface Window {
    dataLayer: any
  }
}

interface Data {
  entryDiscountTimeLimit: string | null
  entrySettlementTimeLimit: string | null
  entryPaymentUrl: string | null
}

interface Methods {
  userAgentType: () => 'd' | 't' | 'm'
}

interface Computed {
  BannerNameForShowing: 'settlement' | 'discount' | null
  currentUser: any | null
  appliedHtmlcssTrial: boolean
  paid: boolean
}

interface Props {}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  head() {
    const env = process.env.NODE_ENV || 'development'
    const scriptTags = scriptTagsByConfig({
      env: env as 'production' | 'development',
      useCase: 'index',
      deviceSize: this.userAgentType(),
    })
    return {
      title: 'プログラミングスクール | 受講者数No.1のテックアカデミー',
      meta: [
        ...metaTags(
          {
            description:
              '【テックアカデミー(TechAcademy)】受講者数No.1のオンラインプログラミングスクール。通過率10%を突破した現役エンジニアからマンツーマンで学べる。転職や副業に活かせるスキルを最短4週間で習得。',
            ogUrl: '/',
          },
          process.env.host as string
        ),
      ],
      script: scriptTags!.script,
      __dangerouslyDisableSanitizersByTagID:
        scriptTags!.__dangerouslyDisableSanitizersByTagID,
    }
  },
  components: {
    TeCommonFixedBottomButton,
  },
  data() {
    return {
      entryDiscountTimeLimit: null,
      entrySettlementTimeLimit: null,
      entryPaymentUrl: null,
      isVisible: false,
      targetSections: [
        '#cover',
        '#ctaTest',
        '#recommend',
        '#occupationBanner',
        '#ctaSecond',
        '#diagnosesTest',
        '#ctaThird',
        '#counseling',
      ],
    }
  },
  computed: {
    BannerNameForShowing() {
      if (this.entrySettlementTimeLimit) {
        return 'settlement'
      } else if (this.entryDiscountTimeLimit) {
        return 'discount'
      } else {
        return null
      }
    },
    currentUser() {
      return (this as any).$store.getters['user/GET_CURRENT_USER']
    },
    appliedHtmlcssTrial() {
      return this.currentUser ? this.currentUser.applied_htmlcss_trial : false
    },
    paid() {
      return this.currentUser ? this.currentUser.paid : false
    },
  },
  async mounted() {
    const {
      data: {
        entryDiscountTimeLimit,
        entrySettlementTimeLimit,
        entryPaymentUrl,
      },
    } = await new IndexServices(this.$api).getTopTimeLimits()
    this.entryDiscountTimeLimit = entryDiscountTimeLimit
    this.entrySettlementTimeLimit = entrySettlementTimeLimit
    this.entryPaymentUrl = entryPaymentUrl
    // Fixedボタンの表示制御
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    this.observer.disconnect()
  },
  methods: {
    userAgentType() {
      if (this.$device.isDesktop) {
        return 'd'
      } else if (this.$device.isTablet) {
        return 't'
      } else {
        return 'm'
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY + 100
      this.isVisible = scrollPosition > 100 && !this.isSectionVisible()
    },
    isSectionVisible() {
      return this.targetSections.some((id) => {
        const element = document.querySelector(id)
        if (element) {
          const rect = element.getBoundingClientRect()
          return rect.top <= window.innerHeight && rect.bottom >= 0
        }
        return false
      })
    },
  },
}

export default Vue.extend(options)
