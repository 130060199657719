import { render, staticRenderFns } from "./TeTopRecommendTest.vue?vue&type=template&id=ea3697c4&scoped=true&lang=pug"
import script from "./TeTopRecommendTest.vue?vue&type=script&lang=js"
export * from "./TeTopRecommendTest.vue?vue&type=script&lang=js"
import style0 from "./TeTopRecommendTest.vue?vue&type=style&index=0&id=ea3697c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea3697c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeCommonTitleSectionBold: require('/vercel/path0/components/Te/TeCommon/Title/TeCommonTitleSectionBold/TeCommonTitleSectionBold.vue').default,TeTopBootcampDeadline: require('/vercel/path0/components/Te/TeTop/TeTopBootcampDeadline/TeTopBootcampDeadline.vue').default,TeCommonBoxCircleGreen: require('/vercel/path0/components/Te/TeCommon/Box/TeCommonBoxCircleGreen/TeCommonBoxCircleGreen.vue').default})
