import { Config, KarteElement, ScriptTags } from '~/constants/scriptUtilities/scriptTypes'
import { scriptTag } from './scriptTag'

// 環境に応じた script tag の返答を期待する。
export const scriptTagsByConfig = ({ env, useCase, karteElement, deviceSize, host, options }: Config): ScriptTags | undefined => {

  if (useCase === 'common') {
    return scriptTag({
      gtm: true, gtag: true, globalSite: true, karteBlock: true
    })
  } else if (useCase === 'commonOptBiz') {
    return scriptTag({
      gtag: true, globalSite: true,
      karteBlock: true
    })
  } else if (useCase === 'index') {
    var script = [
      {
        hid: 'facebookBasePixel',
        innerHTML: innerHTMLForFacebookPixel()
      },
      criteoScriptObject(env),
      {
        hid: 'criteoTag',
        innerHTML: innerHTMLForCriteoHome(deviceSize!),
        type: 'text/javascript',
      },
      {
        hid: 'zenDesk',
        id: 'ze-snippet',
        src:
          'https://static.zdassets.com/ekr/snippet.js?key=be8eb876-5428-4daa-a1f3-862e85def3a6',
        async: true,
        type: 'text/javascript',
      },
      {
        hid: 'zenDeskScript',
        type: 'text/javascript',
        innerHTML: innerHTMLForZenDesk()
      },
      {
        hid: 'karteBlock',
        src: `https://cdn-blocks.karte.io/${process.env.NODE_ENV === 'production' ? 'ec7b3861cfb54f269f118bfed8d152f6' : 'abcd0123'}/builder.js`
      },
    ]
    return {
      script: script,
      __dangerouslyDisableSanitizersByTagID: {
        criteoTag: ['innerHTML'],
        facebookBasePixel: ['innerHTML'],
      },
    }
  } else if (useCase === 'htmlcssTrial') {
    var script = [
      criteoScriptObject(env),
      {
        hid: 'criteoHomeTag',
        innerHTML: innerHTMLForCriteoHome(deviceSize!),
        type: 'text/javascript',
      },
      {
        hid: 'criteoItemTag',
        innerHTML: innerHTMLForCriteoItem(deviceSize!, 'htmlcss-trial'),
        type: 'text/javascript',
      },
      {
        hid: 'zenDesk',
        id: 'ze-snippet',
        src:
          'https://static.zdassets.com/ekr/snippet.js?key=be8eb876-5428-4daa-a1f3-862e85def3a6',
        async: true,
        type: 'text/javascript',
      },
      {
        hid: 'zenDeskScript',
        type: 'text/javascript',
        innerHTML: innerHTMLForZenDesk()
      },
      {
        hid: 'facebookBasePixel',
        innerHTML: innerHTMLForFacebookPixel()
      },
      {
        hid: 'karteBlock',
        src: `https://cdn-blocks.karte.io/${process.env.NODE_ENV === 'production' ? 'ec7b3861cfb54f269f118bfed8d152f6' : 'abcd0123'}/builder.js`
      },
    ]
    return {
      script: script,
      __dangerouslyDisableSanitizersByTagID: {
        criteoHomeTag: ['innerHTML'],
        criteoCartTag: ['innerHTML'],
        criteoItemTag: ['innerHTML'],
        facebookBasePixel: ['innerHTML'],
      },
    }
  } else if (useCase === 'briefing') {
    return {
      script: [
        {
          // ad/facebook/base_pixel
          hid: 'facebookBasePixel',
          innerHTML: innerHTMLForFacebookPixel()
        },
        {
          // zendesk
          hid: 'zenDesk',
          id: 'ze-snippet',
          src: `https://static.zdassets.com/ekr/snippet.js?key=${process.env.NODE_ENV == 'production' ? 'be8eb876-5428-4daa-a1f3-862e85def3a6': '00000000000-0000-000-000000'}`,
          async: true,
          type: 'text/javascript',
        },
        {
          hid: 'zenDeskScript',
          type: 'text/javascript',
          innerHTML: innerHTMLForZenDesk()
        },
        criteoScriptObject(env),
        {
          // criteo_product_detail
          hid: 'criteoItemTag',
          innerHTML: `${env === 'production' ?
            innerHTMLForCriteoItem(deviceSize!, karteElement?.ad_item_id)
            :
            commentOutEveryRow(innerHTMLForCriteoItem(deviceSize!, karteElement?.ad_item_id))}`,
          type: 'text/javascript',
          pbody: true,
        },
        {
          // facebook_dynamic_ad
          hid: 'dynamicFacebookAdTag',
          innerHTML: innerHTMLForDynamicFacebookAd(karteElement),
          type: 'text/javascript',
          pbody: true,
        },
        {
          // google/remarketing_tag
          hid: 'remarketing',
          innerHTML: innerHTMLForRemarketingTag(karteElement),
          type: 'text/javascript',
          pbody: true,
        },
        {
          // karte_send_event
          hid: 'karteSendEvent',
          innerHTML: innerHTMLForKarteSendEvent(karteElement, host),
          type: 'text/javascript',
          pbody: true
        },
        {
          // karte_send_user
          hid: 'karteSendUser',
          innerHTML: innerHTMLForKarteSendUser(karteElement),
          type: 'text/javascript',
          pbody: true
        },
        {
          hid: 'karteBlock',
          src: `https://cdn-blocks.karte.io/${process.env.NODE_ENV === 'production' ? 'ec7b3861cfb54f269f118bfed8d152f6' : 'abcd0123'}/builder.js`
        },
      ],
      noscript: [
        {
          hid: 'facebookPixel',
          innerHTML: `
            <img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=${process.env.NODE_ENV == 'production' ? '321954224680957' : '00000000000'}&ev=PageView&noscript=1"
            />
          `
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        criteoItemTag: ['innerHTML'],
        dynamicFacebookAdTag: ['innerHTML'],
        remarketing: ['innerHTML'],
        karteSendEvent: ['innerHTML'],
        karteSendUser: ['innerHTML'],
        facebookBasePixel: ['innerHTML'],
        facebookPixel: ['innerHTML'],
      },
    }
  } else if (useCase === 'briefing-lp-s') {
    return scriptTag({
      criteoScript: true, criteoItem: { deviceSize: deviceSize, item: karteElement && karteElement.ad_item_id },
      gtag: true, zendeskTag: false,
      facebookPixelCode: true
    })
  } else if (useCase === 'course') {
    return {
      script: [
        criteoScriptObject(env),
        {
          hid: 'criteoTag',
          innerHTML: innerHTMLForCriteoList(deviceSize!),
          type: 'text/javascript',
        },
        {
          hid: 'karteBlock',
          src: `https://cdn-blocks.karte.io/${process.env.NODE_ENV === 'production' ? 'ec7b3861cfb54f269f118bfed8d152f6' : 'abcd0123'}/builder.js`
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        criteoTag: ['innerHTML'],
      },
    }
  } else if (useCase === 'set') {
    return scriptTag({
      gtag: true, zendeskTag: false,
      remarketingTag: karteElement,
      criteoScript: true, criteoItem: { deviceSize: deviceSize, item: karteElement && karteElement.ad_item_id },
      facebookPixelCode: innerHTMLForDynamicFacebookAd(karteElement),
    })
  } else if (useCase === 'seminar') {
    return {
      script: [
        {
          hid: 'facebookBasePixel',
          innerHTML: innerHTMLForFacebookPixel()
        },
        criteoScriptObject(env),
        {
          hid: 'criteoTag',
          innerHTML: innerHTMLForCriteoHome(deviceSize!),
          type: 'text/javascript',
        },
        {
          hid: 'zenDesk',
          id: 'ze-snippet',
          src:
            'https://static.zdassets.com/ekr/snippet.js?key=be8eb876-5428-4daa-a1f3-862e85def3a6',
          async: true,
          type: 'text/javascript',
        },
        {
          hid: 'zenDeskScript',
          type: 'text/javascript',
          innerHTML: innerHTMLForZenDesk()
        },
        {
          hid: 'karteBlock',
          src: `https://cdn-blocks.karte.io/${process.env.NODE_ENV === 'production' ? 'ec7b3861cfb54f269f118bfed8d152f6' : 'abcd0123'}/builder.js`
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        criteoTag: ['innerHTML'],
        facebookBasePixel: ['innerHTML'],
      },
    }
  } else if (useCase === 'yahoo') {
    return scriptTag({
      gtmLyta: true,
      zendeskTag: false,
    })
  } else {
    return {
      script: [],
      __dangerouslyDisableSanitizersByTagID: []
    }
  }
}

const criteoScriptObject = (env: 'development' | 'production') => {
  return {
    hid: 'criteo',
    src: `${env === 'production' ? '//static.criteo.net/js/ld/ld.js' : '//localhost/js/ld/ld.js'}`,
    async: true,
    type: 'text/javascript',
  }
}

const innerHTMLForCriteoHome = (deviceSize: 'd' | 't' | 'm'): string => {
  return `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
    { event: "setAccount", account: 30101 },
    { event: "setSiteType", type: "${deviceSize}" },
    { event: "viewHome" }
    );
  `
}

const innerHTMLForCriteoItem = (deviceSize: 'd' | 't' | 'm', itemName?: string): string => {
  if (itemName == null) return ''
  return `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: 30101 },
      { event: "setSiteType", type: "${deviceSize}" },
      { event: "viewItem", item: "${itemName}" }
    );
  `
}

const innerHTMLForCriteoList = (deviceSize: 'd' | 't' | 'm'): string =>{
  return `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: 30101 },
      { event: "setSiteType", type: "${deviceSize}" },
      { event: "viewList", item: ["113", "100", "pack_25"] }
    );
  `
}

const innerHTMLForKarteSendEvent = (element?: KarteElement, host?: string): string | null => {
  if (element && element.email) {
    return `(function (params) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "karte_event",
        karte_event: "lead",
        karte_params: params
      })
    })({
      "email":"${element.email}",
      "subscription": true,
      "BriefingVideo_date": null,
      "InterestedCourse": "${element.interested_course}",
      "BriefingVideoURL": "${host}${element.BriefingVideoURL}",
      "BriefingVideoDone_date":null,"FreeTrial_date":null,
      "FreeMentoring_date": null,
      "FreeMentoringReservation_date": null,
      "FreeCounseling_date": null,
      "FreeCounselingReservation_date": null,
      "Briefing_date": null,
      "NextNotice_date": null
    })
    `
  } else {
    return null
  }
}

const innerHTMLForKarteSendUser = (element? : KarteElement): string | null => {
  if (element && element.email) {
    return `(function () {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "user_id": null,
        "email": "${element.email}",
        "name": "${element.name}",
        "first_name":null,
        "last_name":null,
        "subscription":true
      })
    })()
    `
  } else {
    return null
  }
}

const innerHTMLForRemarketingTag = (element?: KarteElement): string | null => {
  if (element && Object.keys(element).length !== 0) {
    return `
      gtag('event', 'page_view', {
        'send_to': '${process.env.NODE_ENV == 'production' ? 'AW-1006744060' : 'AW-1111111'}',
        'value': '${element.ad_item_price}',
        'items': [{
          'id': '${element.ad_item_id}',
          'google_business_vertical': 'education'
        }]
      });
    `
  } else {
    return null
  }
}

const innerHTMLForFacebookPixel = () => {
  return `
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '${process.env.NODE_ENV == 'production' ? '321954224680957' : '321954200000'}'); // Insert your pixel ID here.
    fbq('track', 'PageView');
  `
}

const innerHTMLForDynamicFacebookAd = (element?: KarteElement): string | null => {
  return `
    fbq('track', 'ViewContent', {
      contents: [{id: '${element && element.ad_item_id}', quantity: 1}],
      content_type: 'product'
    });
  `
}

const commentOutEveryRow = (rawString: string | null): string => {
  if (rawString == null) return ''
  const row = rawString.split('\n')
  return row.map((str) => `//${str}`).join('')
}

const innerHTMLForZenDesk = () => {
  return `
    window.onload = function(){
      zE(function()
      {zE.hide();});
    }
  `
}
